import React from 'react'
import {Navigate} from "react-router-dom"
import Cookies from "universal-cookie"


const ProtectedRoutes = ({children}) => {

    const cookie = new Cookies()


    const user = cookie.get("superadmintoken")

    if(!user) {
        return <Navigate to="/login" replace />
    }
 return children

};

export default ProtectedRoutes;